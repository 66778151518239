import React from 'react';
import { Layout, SEO } from '../layout';
import { motion } from 'framer-motion';
import WithTransition from '../components/WithTransition';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

const Press = () => (
	<Layout>
		<SEO title="Mídia" />
		<section className="section-padding">
			<div className="container flow">
				<div className="container_press">
					<div className="container_info">
						<p className="text_info_press">Filé de Críticas</p>
						<p className="title_press">
							Aldeia Arapiraca 2019: A corpa de Joana - “Mamilos”,
							da Coletiva Corpatômica (AL)
						</p>
						<p className="text_press">
							Tinha quatorze anos, ela, quando na rua descobriu
							que ter uma corpa poderia ser um crime. Alguém
							puxou-a pelos cabelos, olhou em seus olhos e falou
							que desejava que ela caísse em sua cama. Alguém, um
							homem. Joana descobriu ali, naquele fato, que uma
							menina com uma corpa na rua, querendo apenas passar
							e seguir seu caminho, estaria sempre em risco.
						</p>
						<Link href="https://filedecriticas.blogspot.com/search/label/Mamilos" className="link_press">
							Leia Mais
						</Link>
					</div>

					<StaticImage
						className="image_press"
						alt="mirepi performando"
						src="../images/midia/img.jpeg"
					/>
				</div>
				<div className="divisor"></div>
				<div className="container_press">
					<div className="container_info">
						<p className="text_info_press">Alagoar</p>
						<p className="title_press">
							Crítica: Mulher Pandêmica (dir. Luiza Leal, Maria
							França, Mirella Pimentel, Tayná Nogueira y Yolanda
							Ribeiro)
						</p>
						<p className="text_press">
							As relações interpessoais e intersubjetivas foram
							profundamente transfiguradas nesta pandemia, isso já
							é sabido. Quando falamos da relação com o próprio
							corpo, é mais comum vermos discussões sobre a
							prática de exercícios físicos, dores, sedentarismo…
							Perspectivas meramente objetivas.
						</p>
						<Link href="https://alagoar.com.br/critica-mulher-pandemica-dir-luiza-leal-maria-franca-mirella-pimentel-tayna-nogueira-y-yolanda-ribeiro/" className="link_press">
							Leia Mais
						</Link>
					</div>

					<StaticImage
						className="image_press"
						alt="mirepi performando"
						src="../images/Mulher1.jpg"
					/>
				</div>
			</div>
		</section>
	</Layout>
);

export default WithTransition(Press);
